.service-banner {
  padding: 20px;
  text-align: center;
  background: #eeeeee;
  .row div {
    padding: 12px;
  }
  .borders {
    border-right: 1px solid #c2c7c2 !important;
  }
  .service {
    width: 78px;
    height: 70px;
    display: block;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center bottom;
    margin: 0 auto;
    &.blood {
      background-image: url("./assets/img/blood-test.svg");
      background-size: 75%;
    }
    &.edu {
      background-image: url("./assets/img/Brave\ Hearts.svg");
      background-size: 62%;
    }
    &.adv {
      background-image: url("./assets/img/Charity\ and\ Service.svg");
      background-size: 65%;
    }
    &.camp {
      background-image: url("./assets/img/Welfare.svg");
      background-size: 75%;
    }
  }
  p {
    font-size: 0.9rem;
    margin-top: 14px;
    font-weight: 400;
    color: var(--text-gray-color);
  }
}

.news {
  padding: 3% 0px;
  text-align: center;
  h6 {
    font-size: 1.6rem;
    color: var(--main-color);
    font-weight: 300;
  }
  .news-box {
    img {
      margin-bottom: 20px;
      width: 100%;
      min-height: 260px;
    }
    padding: 10px;
    background-color: #fff;
    text-align: left;
    h5 {
      font-size: 0.9rem;
      word-break: break-all;
      color: var(--main-color);
      height: 52px;
      overflow: hidden;
      text-align: left;
    }
    p {
      margin-bottom: 0px;
      height: 142px;
      overflow: hidden;
      font-weight: 500;
      font-family: "Manjari", sans-serif;
    }
    .footer-area {
      display: block;
      overflow: hidden;
      margin-top: 20px;
      .more {
        float: right;
        font-size: 0.7rem;
        color: var(--main-color);
      }

      .created-by {
        float: left;
        font-size: 0.7rem;
        color: var(--main-color);
      }
    }
  }
  .more-news {
    padding: 11px 43px;
    background-color: var(--main-color);
    color: white;
    border: none;
    margin-top: 4%;
    border-radius: 5px;
  }
}

.advt {
  margin: 5px 0px;
}
.MemberShip {
  background-color: #e50973;
  padding: 4px 10px;
  color: #fff;
  border-radius: 15px;
  width: 126px;
  text-align: center;
  font-size: 0.8rem;
}
.Monthly.MemberShip {
  background-color: #06be82;
}
.Donation {
  background-color: #b29807;
  padding: 4px 10px;
  color: #fff;
  border-radius: 15px;
  width: 126px;
  text-align: center;
  font-size: 0.8rem;
}

@media screen and (min-device-width: 320px) and (max-device-width: 425px) {
  #aboutus {
    .service-banner {
      display: none;
    }
  }
}

.trash {
  background-image: url("../src/assets/img/trash.svg");
  height: 30px;
  width: 20px;
  background-size: 72%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.payment-tab{
h2{
  font-size: 1rem;
  text-transform: capitalize;
}
.Subscribe_list{
  border-bottom: 1px solid #ccc;
  margin-bottom: 22px;
  padding-bottom: 33px;
  ul{
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    li{
      text-align: center;
      display: flex;
      width: 145px;
      border: 1px solid #ccc;
      height: 142px;
      flex-direction: column;
      border-radius: 10px;
      margin: 5px;
      padding: 13px 0px;
      .amount{
        i{ 
          margin-right: 10px;
        }
        font-size: 17px;
      }
      .form-check-input{
        width: 1.5em;
        height: 1.5em;
      }
    }
  }
}
.total {
  font-weight: 600;
  font-size: 01rem;
  text-align-last: right;
}
}