@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~bootstrap/scss/bootstrap";

:root {
  --main-color: #427744;
  --light-gray: #dadada;
  --text-color: #2e2e2e;
  --text-gray-color: #999595;
  --nav-hover: #fff;
  --bg-gray: rgb(247, 245, 245);
}

html,
body {
  font-family: "Poppins", sans-serif;
  font-size: 13px !important;
  line-height: normal;
  font-weight: 300 !important;
  background-color: #ffffff;

  iframe {
    position: inherit !important;
  }
}

p {
  font-size: 0.8rem;
}

button {
  font-size: 0.8rem;
}

#public {
  // img{
  //   width: 100%;
  //   height: auto;
  // }
  a {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    color: inherit;
    text-decoration: none;
  }

  .firstinput {
    display: flex;
  }

  .firstinput input:nth-child(1) {
    margin-right: 10px;
  }

  .submit-btn button {
    width: 33%;
    height: 46px;
    font-size: 0.8rem;
    padding: 7px;
    border: transparent;
    margin-bottom: 21px;
    background-color: var(--main-color);
    margin-top: 20px;
    color: white;
    float: right;
  }

  .thirdinput {
    height: 205px;
  }

  /* header{
    margin: 1rem;
  } */
  .head-portion-img-txt {
    display: flex;
    align-items: center;
    flex: 0.4;
  }

  .head-portion-img-txt img {
    width: 87%;
  }

  header {
    padding: 11px 0px;
  }

  .head-portion-img {
    height: 100%;
    width: 31%;
  }

  .head-portion-txt {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    flex-direction: column;
  }

  .head-portion-txt p {
    font-size: 0.8rem;
    color: var(--light-gray);
    font-weight: 400;
  }

  .input-contact-box {
    margin-bottom: 10px;
    margin-left: 11%;
  }

  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.55);
    // border-color: rgb(53 50 50 / 0%);
  }

  .input-contact-box:nth-last-child(1) {
    border-top: 1px solid var(--light-gray);
    padding-top: 5%;
    margin-top: 8%;
  }

  .head-portion-txt h1 {
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 0px;
  }

  .head-portion img {
    height: 100%;
    width: 100%;
  }

  .head-portion {
    display: flex;

    justify-content: center;
    align-items: center;
  }

  .head-portion-contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;

    flex: 0.3;
  }

  .head-portion-contact p {
    font-size: 1rem;
    font-weight: 500;
  }

  .head-portion-contact h3 {
    color: var(--main-color);
    padding-bottom: 5px;
    font-size: 1rem;
  }

  .social-me-icons {
    display: flex;
    justify-content: space-between;
    margin-top: 14%;
    font-size: 1.4rem;
    color: var(--main-color);
  }

  .green-nav {
    position: sticky;
    top: 0;
    /* // background-color: var(--main-color);  */
    padding: 5px 0px;
    background: linear-gradient(
      116deg,
      var(--main-color) 70%,
      rgb(25, 26, 25) 50%
    );
    z-index: 99;
  }

  // .contact-callus ,.email{

  //   border-right: 1px solid #ccc;
  // }
  .contact-callus h3,
  .email h3 {
    font-size: 0.8rem;
    color: var(--main-color);
  }

  .contact-callus p,
  .email p {
    font-size: 0.9rem;
    font-weight: 500;
  }

  .top {
    padding-top: 11px;
  }

  .navs {
    background-color: var(--main-color);
    width: 88%;
    /* display: flex;
text-align: center;
align-items: center;
justify-content: center;
font-size: 1rem;
font-weight: 200; */
  }

  .nav-link.active {
    color: var(--nav-hover) !important;
    font-weight: 400;
  }

  a.nav-link:hover {
    color: var(--nav-hover) !important;
    //font-weight: 400;
  }

  .nav a {
    color: rgb(218, 216, 216);
    text-decoration: none;
    color: white;
    font-weight: 200;
    font-size: 0.8rem;
    letter-spacing: 1px;
  }

  a.nav-link {
    padding-right: 3rem !important;
  }

  .nav a.active {
    color: #fff;
    font-weight: 400;
  }

  .nav-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar-nav {
    /* display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 40rem;
  margin-right: 14rem; */
  }

  .triangle-box {
    background: linear-gradient(136deg, #5b7742 50%, rgb(25, 26, 25) 50%);
    width: 4rem;
  }

  .sign-in-box {
    text-align-last: right;
    display: flex;
    justify-content: end;
  }

  button.donate-btn {
    padding: 13px;
    background: transparent;
    color: #fff;
    border: none;
    position: relative;
    margin-right: 23px;
  }

  .donate-btn i {
    font-weight: 900;
    color: #5b7742;
    font-size: 1.5rem;
    position: absolute;
    left: -22px;
    top: 8px;
  }

  button.sign-btn {
    padding: 7px 19px;
    border-radius: 4px;
    text-align: center;
    border: none;
    background-color: #427744;
    color: white;
    height: 30px;
    margin-top: 5px;
  }

  .input-contact-box i {
    margin-right: 9px;
    color: var(--main-color);
  }

  .input-contact-box p {
    font-size: 0.79rem;
    font-weight: 600;
  }

  .input-contact-box h4 {
    font-size: 1.1rem;
    margin-bottom: 30px;
  }

  .sign-in-box {
    /*  
 width: 61%;
  display: flex;
  align-items: center;
  justify-content: space-around; */
  }

  .donate-btn {
  }

  .sign-btn {
  }

  .map {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(./assets/img/googlemap.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: darken;
    height: 30vh;
  }

  .map h1 {
    font-size: 2rem;
    font-weight: 400;
    color: white;
  }

  .map p {
    //color: rgb(210 205 205);

    text-align: center;
  }

  .contact-address {
    margin-top: 8%;
  }

  // .navbar-nav{
  //   width: 100%;
  // }

  .navbar .container-fluid {
    padding-left: 0px;
  }

  li.nav-item {
    position: relative;
    text-align: left;
  }

  li.nav-item::after {
    content: " ";
    position: absolute;
    background-color: #a3bd8c;
    width: 1px;
    right: 20px;
    height: 44%;
    top: 13px;
  }

  li.nav-item:nth-last-child(1):after {
    display: none;
  }

  .nav-link {
    font-size: 0.9rem;
    padding: 14px 0px 12px 0px;
    color: #e3e3e3 !important;
    font-weight: 200;
    letter-spacing: 1px;
  }

  .navbar {
    padding: 0px;
  }

  /* .nav-item{
  display: flex;
} */
  .proper-map {
    background-image: url(assets/img/proper-map.PNG);
    background-size: cover;
    background-repeat: no-repeat;
    height: 43vh;
    margin-top: 57px;
  }

  .connect-with-us {
    color: var(--light-gray);
  }

  .ctdc {
    padding: 43px 0px 0px 0px;
    background: rgb(50, 51, 50);
    margin-top: 33px;
  }

  .ctdc-first-colomn {
    flex: 1.5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 200;
  }

  .ctdc-head-box {
    width: 50%;
  }

  .ctdc-head h3 {
    color: white;
    font-size: 1.2rem;
  }

  .ctdc-head p {
    color: var(--light-gray);
    font-size: 0.9rem;
    margin-top: 35px;
    margin-bottom: 5px;
  }

  .ctdc-paragraph {
    font-size: 1.2rem;
    line-height: 2rem;
    color: white;
    // padding-top: 1.7rem;
  }

  .ctdc-btn button {
    font-size: 0.8rem;
    color: rgb(214, 210, 210);
    background: var(--main-color);
    /* width: 38%; */
    /* height: 2rem; */
    border-radius: 5px;
    border-style: none;
    padding: 10px 34px;
  }

  .ctdc-btn button:hover {
    background: #4c5034;
  }

  .ctdc-second-colomn p {
    color: white;
  }

  .ctdc-second-colomn {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    font-size: 16px;
    flex: 0.5;
    font-weight: 400;
  }

  .ctdc-second-colomn p {
    color: rgb(233, 230, 230);
    line-height: 1rem;
  }

  .ctdc-second-colomn h6 {
    color: rgb(233, 230, 230);
    padding-bottom: 1rem;
  }

  .last-icons {
    font-size: 1.7rem;
    display: flex;
    justify-content: start;
  }

  .connect-with-us h6,
  .ctdc-third-colomn h6,
  .news-latter h6 {
    font-size: 0.9rem;
    color: var(--light-gray);
    margin-bottom: 26px;
  }

  .ctdc-third-colomn a {
    color: var(--light-gray);
  }

  .last-icons i:hover {
    color: #4c5034;
  }

  .last-icons i {
    color: var(--main-color);
    cursor: pointer;
    margin-right: 25px;
  }

  .ctdc-third-colomn p {
    font-size: 1rem;
    color: rgb(233, 230, 230);
  }

  .ctdc-third-colomn a {
    font-size: 0.8rem;
    text-decoration: none;
    margin-bottom: 18px;
    display: block;
    font-weight: 400;
  }

  .news-latter {
    display: flex;
    flex-direction: column;
    flex: 0.8;
    align-items: end;
    justify-content: end;
    font-weight: 200;
  }

  .footer-portion {
    margin-top: 43px;
  }

  .news-latter-box {
    height: 9%;
  }

  button.news-latter-btn {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: var(--main-color);
    margin-top: 15px;
  }

  .news-latter input {
    width: 86%;
    height: 37px;
    margin: 0.5rem;
    font-size: 0.8rem;
    background-color: #90a37e;
    border-style: none;
    border-radius: 5px;
  }

  .news-latter ::placeholder {
    color: rgb(37, 42, 48);
    font-size: 0.8rem;
  }

  /* .news-latter-btn button{
  font-size: 13px;
  
  background: #5B7742;
  color: rgb(214, 210, 210);
  width: 45%;
  height:2.7rem ;
  border-radius: 5px;
  border-style: none;
  margin-top: 1rem;
  
} */
  .footer-portion ul {
    display: flex;
    justify-content: center;
    background: rgb(32, 32, 32);
    align-items: center;
    color: rgb(100, 98, 98);
    font-size: 16px;
    padding: 1px 0px;
    margin-bottom: 0px;
  }

  li.footer-nav-item {
    padding: 0px 10px;
    list-style: none;
    font-size: 0.8rem;
  }

  li.footer-nav-item a,
  li.footer-nav-item p {
    color: var(--light-gray);
    text-decoration: none;
  }

  .footer-portion p {
    padding: 1rem 0rem 0rem 1rem;
  }

  .footer-portion a {
    color: rgb(100, 98, 98);
  }

  .footer-portion-box {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
  }

  .last-vertical-line {
    background: rgb(100, 98, 98);
    height: 2rem;
    width: 1px;
  }

  .contact-callus a,
  .email a {
    color: var(--text-color);
    font-size: 0.8rem;
  }

  .event-box {
    background-color: white;

    .img-pre {
      height: 312px;
      overflow: hidden;
      width: 355px;
      padding-left: 10px;
      padding-right: 5px;
    }

    .title {
      background-color: var(--main-color);
      position: relative;
      padding-top: 15%;
      padding-bottom: 6px;
      padding-left: 10px;

      h6 {
        color: white;
        text-align: left;
        font-size: 0.89rem;
        font-weight: 500;
        font-family: "Manjari", sans-serif;
      }

      .date {
        position: absolute;
        left: 10px;
        top: -30px;
        display: block;
        width: 84px;
        text-align: center;
        background-color: #fff;
        height: 61px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      span {
        display: block;
        overflow: hidden;
        color: var(--main-color);
        text-align: center;

        &.label {
          font-size: 1.5rem;
          line-height: 38px;
          font-weight: 500;
          position: relative;

          &::after {
            content: " ";
            margin: 0 auto;
            height: 1px;
            width: 53%;
            background-color: #dadada;
            position: absolute;
            bottom: 6px;
            left: 25%;
          }
        }

        &.date-month {
          font-size: 0.8rem;
        }
      }
    }
  }

  .file-preview img {
    width: 150px;
  }

  .btn-check:active + .btn-success,
  .btn-check:checked + .btn-success,
  .btn-success.active,
  .btn-success:active,
  .show > .btn-success.dropdown-toggle {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }

  .btn-check:focus + .btn-success,
  .btn-success:focus {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: var(--main-color);
  }

  .Razorpay-btn {
    font-size: 0.8rem;
  }

  .Donate-btn {
    .subscrib {
      button {
        background-color: var(--main-color);
        font-size: 0.8rem;
        text-align: center;
        color: #fff;
        border: none;
        margin: 8px;
        left: -13px;
        position: relative;
        top: -3px;
      }
    }
  }

  @media screen and (min-device-width: 320px) and (max-device-width: 450px) {
    html,
    body {
      font-size: 10px !important;
      line-height: 12px !important;
    }

    .head-portion-img-txt img {
      width: 57%;
    }

    .head-portion-txt h1 {
      font-size: 1rem;
    }

    .top {
      display: none;
    }

    #slide {
      display: none;
    }

    .donation-list {
      .donation-container {
        .bar {
          background-color: #f5f5f5;
          width: 100% !important;
          position: absolute;
          right: 0px !important;
          left: 0px !important;
          bottom: 8px;
          display: flex;
          padding: 20px;
          justify-content: space-between;

          .Rupee {
            display: none !important;
          }
        }
      }
    }

    button.sign-btn {
      background-color: #fff;
      color: #191a19;
      display: table;
      min-width: 77px;
      height: 25px;
      margin-top: 4px;
    }

    .Donate-btn .subscrib button {
      background-color: #fff;
      top: 5px;
      color: #191a19;
      margin: 0px;
    }

    .green-nav {
      background: linear-gradient(141deg, var(--main-color) 95%, #191a19 50%);
      z-index: 2;

      .menu {
        padding: 0px;

        .container-fluid {
          padding: 0px;
          margin: 0px;

          ul {
            li {
              a {
                margin: 0px;
                padding-left: 10px;
                color: #fff !important;
              }
            }
          }
        }
      }
    }

    .sign-in-box {
      position: absolute;
      top: 5px;
      right: 6px;
    }

    li.nav-item::after {
      display: none;
    }

    .ctdc-third-colomn {
      display: none;
    }

    .modal.left .modal-dialog,
    .modal.right .modal-dialog {
      width: 300px !important;
    }

    #accordionSidebar {
      display: none;
    }
  }

  .modal.left .modal-dialog,
  .modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 900px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }

  .modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
  }

  .modal.right .modal-body {
    padding: 15px 15px 80px;
  }

  .fade.modal.right .modal-dialog {
    right: -900px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }

  .fade.modal.right.show .modal-dialog {
    right: 0;
  }

  .adminpanel {
    .modal-content {
      border-radius: 0px;
    }

    .form-label {
      margin-bottom: 0.5rem;
      font-weight: 400;
      font-size: 0.8rem;
    }

    input {
      height: 35px;
      font-size: 0.7rem;
    }

    .modal-title {
      font-size: 0.9rem;
      font-weight: 300;
      text-transform: capitalize;
    }

    button {
      &.btn {
        padding: 7px 19px;
        border-radius: 4px;
        text-align: center;
        border: none;
        background-color: #427744;
        color: white;
        height: 30px;
        margin-top: 5px;
        line-height: 1rem;
        font-size: 0.8rem;
        font-weight: 300;
      }
    }

    .add-btn {
      float: right;
    }
  }

  .mandatory {
    color: red;
    font-size: 0.9rem;
    font-weight: 400;
  }

  button#dropdown-basic {
    background-color: transparent;
    height: 41px;
    margin-top: 0px;
    padding: 0px;
    margin-bottom: 0px;
  }

  .rdt_TableHeader {
    display: none !important;
  }

  .due {
    display: block;
    text-align: center;
    background-color: #e4172b;
    padding: 20px;
    color: white;
    font-size: 1.4rem;
    text-transform: capitalize;
  }

  .spinner-border {
    position: absolute;
    top: 50%;
    left: 56%;
    border-right-color: white;
  }

  .file-upload-wrapper {
    position: relative;

    [type="file"] {
      margin: 0px;
      position: absolute;
      left: 0;
      opacity: 0;
    }

    // [type="file"] + label {
    //   background: #f15d22;
    //   border: none;
    //   border-radius: 5px;
    //   color: #fff;
    //   cursor: pointer;
    //   display: inline-block;
    //   font-family: 'Rubik', sans-serif;
    //   font-size: inherit;
    //   font-weight: 500;
    //   margin-bottom: 1rem;
    //   outline: none;
    //   padding: 1rem 50px;
    //   position: relative;
    //   transition: all 0.3s;
    //   vertical-align: middle;

    // }

    .filelabel {
      position: relative;
      width: 100%;
      border: 1px solid #ccc;
      padding: 6px 10px;
      border-radius: 5px;
      padding-left: 114px;
      font-size: 0.9rem;
      height: 35px;
      overflow: hidden;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &::before {
        content: "Choose a file";
        position: absolute;
        left: 0px;
        background-color: #198754;
        color: #fff;
        height: 33px;
        padding: 7px 16px 5px 16px;
        font-size: 0.7rem;
        font-weight: 400;
        top: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        cursor: pointer;
      }
    }
  }

  .widget {
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    min-height: 103px;
    padding: 15px;
    box-shadow: 2px 6px 4px -1px #b3bfb340;

    h3 {
      font-size: 1rem;
    }

    .value {
      font-size: 2rem;
      font-weight: 600;
    }
  }

  .accessdenied {
    display: none;
    margin: 0 auto;
    text-align: center;
    padding-top: 6%;
    padding-bottom: 6%;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    html,
    body {
      font-size: 11px !important;
    }

    #slide .carousel-caption h5 {
      line-height: 37px;
    }

    .sign-btn {
      background-color: var(--main-color);
      font-size: 0.8rem;
      text-align: center;
      color: #fff;
      border: none;
      margin: 8px;
      position: relative;
      top: -3px;
    }
  }
  .activity-row-items-box a {
    padding: 0px !important;
  }
}

.userMenu {
  .accessdenied {
    h1 {
      display: none;
    }

    p {
      display: none;
    }
  }

  button {
    background: transparent;
    border: none;
    margin: 0px;
    padding: 0px;

    img {
      width: 42px;
      border-radius: 100%;
      height: 42px;
    }

    &:hover {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }

    &:after {
      position: absolute;
      bottom: -5px;
      left: 13px;
      /* right: 0; */
      text-align: center;
    }
  }

  .dropdown-menu.show {
    border: none;
    border-radius: 0px;
    font-size: 0.9rem;
    box-shadow: 1px 6px 9px 0px #000000b0;
    text-align: center;
    text-align-last: center;
    width: 212px;
    min-height: 133px;

    a {
      font-size: 0.8rem;
      font-weight: 400;

      text-align: center;
      padding: 10px 0px;
    }

    div {
      display: block;
      width: 100%;
      /* text-align: center; */
      overflow: hidden;
      font-weight: 400;
      font-size: 0.9rem;
      text-align-last: left;
      padding: 0px 11px;
      text-transform: uppercase;
      margin-bottom: 11px;
    }
  }
}

.export {
  margin-left: 10px;
  height: 30px;
  width: 30px;
  position: relative;
  top: 8px;
  background-color: transparent;
  border: none;
  background-image: url("./assets/img/icons8-microsoft-excel.svg");
  background-size: cover;
  cursor: pointer;
  margin-left: auto;
  order: 2;
}

.users-list-table {
  position: relative;

  .export {
    position: absolute;
    right: 13px;
    top: 80px;
    z-index: 999;
  }

  input#custom-switch2,
  input#custom-switch1 {
    width: 20px;
    height: 20px;
  }
}

.edit {
  background-image: url("./assets/img/edit.svg");
  height: 30px;
  width: 20px;
  background-size: 72%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.table-toolbar {
  width: 100%;
  display: flex;

  input#search {
    margin-bottom: 0px;
  }
}

.react-datepicker-wrapper {
  input {
    height: 35px;
    border-radius: 5px;
  }
}

input {
  width: 100%;
  height: 46px;
  font-size: 0.8rem;
  padding: 7px;
  border: 1px solid #ccc;
  margin-bottom: 21px;
}

textarea {
  width: 100%;
  height: 100%;
  font-size: 0.8rem;
  padding: 7px;
  border: 1px solid #ccc;
  margin-bottom: 21px;
}

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  background-color: #000805c7;
  height: 100%;
  z-index: 999;
  top: 0;
}
