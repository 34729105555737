#profile-view{

    .profile{
        display: flex;
        justify-content:center;
        align-items:center;
    }
    .user-avatar {
        align-items: center;
        height:200px;
        width: 200px;
        border: 2px solid var(--main-color);
        background-color: white;
        border-radius: 50%;
          img {
            background-size: 50%;
            background-repeat: no-repeat;
            background-position: center;
            margin: 0 auto;
            clip-path: circle();
           
          }
      }
      .family-btn
    {
        background-color: var(--main-color);
        width: 20px;
    height: 20px;
    border-radius: 69%;
    float: right;
    }

    i.fas.fa-plus-circle {
        float: right;
        color:  var(--main-color);cursor: pointer;
    }
    i.fas.fa-trash-alt{
    float: right;
    position: relative;
    right: -30px;
    top: -26px;
    font-size: 0.8rem;
    cursor: pointer;
    }
    div.col{
      width: 98%;
    }
    .form-control{
      margin: 0px;
    }
    .file-upload-field{
      margin: 0px;
    }
}

.active_roles{
  margin: 0;
  padding: 0;
  li{
    padding: 10px 0px;
    border-bottom: 1px solid #e2dbdb;
    list-style: none;
    position: relative;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: capitalize;
    i{
      position: absolute;
    right: 0;
    cursor: pointer;
    font-size: .9rem;
    top: 21px;
    }
}
  
}

.userPf{
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
}

.user-menus{
  background-color: #fff;
    margin: 14px 0px;
    border-radius: 5px;
    padding: 10px;
  float: right;
    button.btn{
      margin:0px !important;
      margin-right:5px !important;

    }
}

.users-list-table{
  background-color: #fff;
  position: relative;
  #heading{
    float: left;
    padding: 17px 0px 0px 16px;
  }
  .filter{
    right: 10px;
    position: absolute;
    button{
      color: #000;
    }
  }
}
.users-list-table .rdt_TableRow{
  >div:nth-child(1){
    min-width: 2px;
    max-width: 2px;
  }
}

.sc-jJoQJp.bdGmnx{
  display: none;
}

.widget{
  position: relative;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  .value{
    font-size: 2.4rem;
    font-weight: 600;
    color: #427744;
  }
  .icon{
    width: 42px;
    height: 42px;
    top: 1px;
    background-size: cover;

    position: absolute;
    right: 10px;
    background-image: url('../../../assets/img/statistics.png');
  }

}

.whatp{
  
    right: 10px;
    background-image: url('../../../assets/img/whatsapp.png');
    padding-left: 23px;
    background-size: 19%;
    background-repeat: no-repeat;
}

.call{
  right: 10px;
  background-image: url('../../../assets/img/missed-call.png');
  padding-left: 23px;
  background-size: 19%;
  background-repeat: no-repeat;
}


.permission{
  .nav-item{
    button{
      display: table;
    width: 153px;
    color: #ffffff !important;
    background-color: #427744;
    font-size: 0.7rem;
    padding: 7px 10px !important;
    &.active{
      background-color: #658766;
    }
    }
   &:after{
     display: none;
   }
  }
}