#settings{
    *{
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    .nav-pills .nav-link {
      background: rgb(46, 44, 44);
      border: 0;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    padding: 2px;
   }
.col-sm-10{
  padding: 10px;
  }
.Tabs{
    height: 60px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 60px;
    cursor: pointer;
    padding-left: 25px;
    transition: all 0.5s ease;
    z-index: 12;
    margin-bottom: 2px;
   }
  .Tabs:hover{
    background-color: var(--main-color);
   }
  
}
