#assets{
    .row{
        //background-color: yellow;
        display: flex;
        width: auto;
    
    }
    .asset{
        //height: 195px;
        width: 230px;
        background-color: white;
        margin: 15px;
        padding: 10px;
    }
    .asset-trash{
        background-image: url('../../../assets/img/trash.svg');
        height: 30px;
        width: 20px;
        background-size: 72%;
        background-repeat: no-repeat;
        cursor: pointer;
        float: right;
    }
}