a.userPf {
  width: 40px;
  height: 40px;
  background-color: rgb(155, 154, 154);
  border-radius: 100%;
  i {
    font-size: 23px;
    line-height: 0px;
    position: relative;
    top: 12px;
    left: 10px;
    color: white;
  }
}

#membership-Manage {
  .tableheader {
    margin: 10px;
    h4{
        span{
            font-size: 0.9rem;
            margin: 2px;
        }
    }
    .filterbyDate {
      display: flex;
      width: 28%;
    }
    .export {
      position: absolute;
      right: 8px;
      top: 5px;
      z-index: 999;
    }
  }
}
