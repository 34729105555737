#events{
    padding-top: 36px;
    background-color: #fff;
    h1{
        font-size: 1.6rem;
        color: var(--main-color);
        font-weight: 300;
        margin-bottom: 50px;
        
    }
.event-list{
    border-right: 1px solid #ccc;
}
.event-box {
    background-color: white;
    height: 247px;
    p{
        padding: 10px;
        font-weight: 500;
        font-family: 'Manjari', sans-serif
    }
}

}

.events-row-items-box{
    .row{
        border-bottom: 1px solid #ccc;
        margin-bottom: 20px;
     h5{
        font-weight: 600;
        font-size: 1rem;
     }
    }
}