

#slide{
    .carousel-caption {
        top: 7%;
        left: 0;
        right: 0;
        text-align: left;
        h5{
            font-size:2rem;
            font-weight: 800;
    color: var(--main-color);
    line-height: 32px;
    width: 69%;
        }
        label{
            color: #414950;
            font-size: 0.9rem;
            line-height: 49px;
        }
        p{
            font-size: 1rem;
            color: #33700d;
            width: 50%;
    line-height: 22px;
    margin-top: 31px;
        }
        a{
            background-color: #98b836;
            display: table;
            padding: 10px;
            border-radius: 6px;
            font-size: 0.9rem;
            margin-top: 37px;
        
        }
      
}

.carousel-indicators{
    bottom: 39px;
}
.carousel-indicators [data-bs-target] {

    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: transparent;
    border: 2px solid #ccc;
    margin: 0px 17px;
}
.carousel-indicators .active {
    opacity: 1;
    background-color:var(--main-color);
}
.btns{

    position: absolute;
    left: 0;
    right: 0;
    bottom: 23px;

    button{
        margin: 0px;
        border: none;
        background-color:var(--main-color);
        span{
            width: 1.4rem;
        }
    }
}
}

// .news{
//     .row{
//         display: flex;
//     }
//     .event{
//         display: flex;
//         margin-right: 3px;
//     }
//     .event-title p{
//        color: var(--main-color);
//        font-size: 1rem;
//        font-weight: 600;
//     }
//     .home-event{
//         display: flex;
//     }
//     // .col-lg-6{
//     //     display: flex;
//     // }
//     // div.slider{
//     //     display: flex;
//     // }
// //     .Slide{
// // display: flex;
// //     }
// }
.event{
  
    padding: 3% 0px;
    text-align: center;
    h6{
        font-size: 1.0rem;
     
        font-weight: 400;
        width: 29%;
        margin: 0 auto;
        margin-bottom: 18px;
        color: var( --main-color);
    }
    
    p{
        font-weight: 400;
        line-height: 23px;
    }
    .event-row{
        margin-top: 35px;
        .event-row-items{
            position: relative;
            h2{
                position: absolute;
    top: 40%;font-weight: 300;
    text-align: center;
    width: 100%;
    color: #fff;
            }
            .more{
                width: 83px;
                height: 34px;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                position: absolute;
                bottom: 9px;
                left: 0;
                z-index: 1;
                background-color: #ffffff;
               
                i{
                    position: absolute;
                    right: 5px;
                    font-size: 1.6rem;
                    color: #0f5132;
                    top: 5px;
                }
            }
        }
        .event-row-items-box{
            img{
                margin-bottom: 20px;
            }
            padding: 10px;
            background-color: #fff;
            text-align: left;
            h5{
                font-size: 0.9rem;
                word-break: break-all;color: var(--main-color);
            }
          p{
              margin-bottom: 0px;
          }
          .footer-area{
            display: block;
            overflow: hidden;
            margin-top: 20px; 
            .more{
                float: right;
                font-size: 0.7rem;
                color: #5b7742;

            }

            .created-by{
                float: left;
                font-size: 0.7rem;
                color: #5b7742;
            }
          }
           
        }
    }
}


.donation{
    .donation-img{background-image: url('../../assets/img/pic1.png');
        background-repeat: no-repeat;
        background-position: left;
      
        background-size: cover;
    height: 100%;
    width: 100%;}
    
    background-color: #95a389;    overflow: hidden;
    .donation-container{
        padding-top: 10%;margin-right: 22%;
        h4{
            color: var(--main-color);
        }
    .donation-card{
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;margin-top: 7%;

        li{
            list-style: none;
            width: 106px;
            height: 107px;
            background-color: white;
            margin: 10px;
            background-repeat: no-repeat;
            background-position: center;
            &.donation-n{
                background-image: url('../../assets/img/Group%2086.svg');
                background-size: 62%;
            }
            &.project{
                background-image: url('../../assets/img/08dac6c0f58ff3fc756c0bc02292b53a.svg');
                background-size: 62%;
            }
            &.bea{
                background-image: url('../../assets/img/Group%2089.svg');
                background-size: 62%;
            }
            &:nth-child(1){
                margin-left: 0px;
            }
        }
    }
}
}


.upcomingevents{
    background-image: url('../../assets/img/bg.jpg');
    background-size: cover;
    text-align: center;
    padding: 20px 0px;
    h6{
        font-size: 1.6rem;
       
        font-weight: 300;
        color: #fff;
        font-weight: 500;
        font-family: 'Manjari', sans-serif
    }
    .upcoming-events{
        margin: 5% 0px;
    }
    
    p{
        font-size: 0.9rem;
        text-align: left;
        padding: 10px;
        height: 108px;
        overflow: hidden;
        font-weight: 500;
        font-family: 'Manjari', sans-serif
    }
    .footer-area{
        display: block;
        overflow: hidden;
        margin-top: 20px; 
        .more{
            float: right;
            font-size: 0.7rem;
            color: var(--main-color);
    
        }
        padding: 10px;
      }
      .more-btn{
        padding: 10px 35px;
        border: 1px solid #fff;
        border-radius: 5px;
        background-color: transparent;
        margin-top: 51px;
    
      }
}

.donation-list{
.donation-container{
    padding: 6% 0px;
position: relative;
height: 100%;
p{
    line-height: 26px;
    height: 153px;
    overflow: hidden;
    font-weight: 500;
    font-family: 'Manjari', sans-serif
}
    h6{
        
        font-size: 1.2rem;
        color: var(--main-color);
        font-weight: 300;
        height: 88px;
        overflow: hidden;
        line-height: 30px;
        text-overflow: ellipsis;
        margin-bottom: 20px;
        font-weight: 500;
        font-family: 'Manjari', sans-serif
    }
    .bar{
        background-color: whitesmoke;
        width: 86%;
        position: absolute;
        right: 105px;
        bottom: 8px;
        display: flex;
        padding: 20px;
        justify-content: space-between;
    i{
        font-size: 2rem;  color: var(--main-color);
    }
    button{
        font-size: .8rem;
    color: #d6d2d2;
    background: var(--main-color);
color: #fff;
    border-radius: 5px;
    border-style: none;
    padding: 10px 21px;
    }
    .amount{
        font-size: 2rem; color: var(--main-color);margin-bottom: 12px;display: block;
       
    }
    .Rupee {
        background-image: url('../../assets/img/Indian-Rupee-symbol.svg');
        height: 38px;
    width: 25px;
    background-size: cover;
    display: block;
    position: absolute;
    left: 115px;
    }
    .labal{
        font-size: 0.7rem;
    }
    }
}
.donation-img{
position: relative;


}
}
.activity-row-items-box{
    background-color: #fff; 
    position: relative;
    a{
        padding: 0px !important;
    }
    h5{
        background-color: #000000a8;
        position: absolute;
        bottom: -7px;
        left: 0;
        right: 0;
        height: 92px;
        color: #fff;
        padding: 20px;
        font-size: 0.9rem;
        text-align: left;
        font-weight: 500;
        font-family: 'Manjari', sans-serif
    }
}


@media screen and (min-device-width: 320px) and (max-device-width: 425px) {
#home{
    .service-banner {
        padding: 5px;
        .service{
          width: 46px;
          height: 38px;
         
        }
        p {
          font-size: 0.6rem;}
      }
      .event h6 {

        width: 100% !important;
      }
      .donation{
        background-image: none !important;
      }
      .event .event-row {
         margin-top: 0px !important;
      }
      
      .activity-row-items-box {
        margin-bottom: 10px;
      }
      .upcomingevents{
        background-image: none !important;
      }
      .donation {
      .donation-container {
      .donation-card{
       li {
        
        
        width: 69px;
        height: 62px;
       }
    }      
}  }  
}
   

 }