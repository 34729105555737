
#event-mangement{
    .tab-content {
  
      height: 700px;
  }
  div#uncontrolled-tab-example-tabpane-calender {
    height: 100%;
  }
  .card{
    height: 100%;
  }
  .tab-content{
    padding: 0px;
  }
  button{
    &.btn {

    
    padding: 7px 19px;
    border-radius: 4px;
    text-align: center;
    border: none;
    background-color: #427744;
    color: white;
    height: 30px;
    margin-top: 5px;
    line-height: 1rem;
    font-size: 0.8rem;
    font-weight: 300;
    margin-bottom: 24px;
  }
}
.add-btn{
  float: right;
}
  }
  