#forget{
    min-height: 407px;
    .form-control{
        height: 35px;
    font-size: 0.8rem;
    }
    .form-label {
        margin-bottom: 0.5rem;
        font-weight: 400;
        font-size: 0.8rem;
    }
    .submit-btn{
        background-color: var(--main-color);
    font-size: 0.8rem;
    text-align: center;
    padding: 7px 37px;
    color: #fff;
    border: none;
    &:focus{
        border: none;
        box-shadow: none;
    }
    }
    a{
        float: right;
        margin-bottom: 21px;
        font-size: 0.8rem;
        color: #427744;
        font-weight: 500;
    }
    }