#gallery {
  margin-top: 20px;
  h1 {
    font-size: 1.6rem;
    color: var(--main-color);
    font-weight: 300;
  }
  .card {
    padding: 0px;
    border: none;
    img {
      margin: 5px;
    }

    iframe {
      border: 0;
      width: 100%;
      height: 241px;
      margin: 5px;
    }
  }
  .videos {
    border-top: 1px solid #ccc;

    iframe{
      margin: 20px;
      border: 1px solid #427744;
      padding: 10px;
    }
    
  }

  .filter {
    margin-top: 6%;
    margin-bottom: 6%;
    display: flex;
    justify-content: flex-start;
    span {
      background-color: var(--main-color);
      color: #fff;
      padding: 5px 29px;
      border-radius: 10px;
      font-size: 0.8rem;
      cursor: pointer;
      margin-right: 20px;
    }
  }
}

#folder {
  padding-top: 36px;
  background-color: #fff;
  h1 {
    font-size: 1.6rem;
    color: var(--main-color);
    font-weight: 300;
    margin-bottom: 50px;
  }
  h5 {
    margin: 10px 0px;
  }
  .folderIcon {
    width: 100px;
    height: 100px;
    background-size: cover;
    margin: 0 auto;
    background-image: url("../../assets/img/icons8-folder-375.png");
  }
  .card-title {
    text-align: center;
    font-size: 1rem !important;
  }
  .gallery-title {
    display: flex;
    .years {
      display: block;
      margin-left: 32px;
    }
  }
}
