#news{
    background-color: #fff;
    h1{
        font-size: 1.6rem;
        color: var(--main-color);
        font-weight: 300; 
        text-align: left;
    } 
    .story{
        padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: left;
        h1{
            font-size: 1.6rem;
            color: var(--main-color);
            font-weight: 300; 
            font-weight: 500;
            font-family: 'Manjari', sans-serif

        } 
        p{
            font-weight: 500;
            font-family: 'Manjari', sans-serif
        }
        img{
            width: 100%;
            height: auto;
        }
    }
    .news-box{
        margin-bottom: 20px;
    }  .nav-link {
        font-size: 0.9rem;
        padding: 14px 0px 12px 0px;
        color: #0f0000 !important;
        font-weight: 400;
        letter-spacing: 1px;
        text-align: right;
    }

}

#news_details{
    margin-top: 20px;
    .news_details-container{
        border-right: 1px solid #ccc;
    padding-right: 21px;
    height: 100%;
    h1{
        font-size: 1.6rem;
        color: var(--main-color);
        font-weight: 300; 
        
    } 
    img{
        border: 7px solid #f1f1f1;
        margin-bottom: 20px;
    }
    .createdAt{
        font-size: 0.9rem;
    margin-bottom: 20px;
    display: block;
    }
    .social{
        display: flex;
        justify-content: space-between;
        color: var(--main-color);
        font-size: 1.4rem;
    }
    }

    
      .left-col{
          display: none;
      }
    
}