#Administration{
    margin-top: 20px;
    h1{
        font-size: 1.6rem;
        color: var(--main-color);
        font-weight: 300;
      
      
      }
    
      .heading{
          background-color: var(--main-color);
          padding: 10px 0px 10px 20px;
          border-radius: 4px;

         
      }
      p{
          color: white;
          font-size: 0.8rem;
          margin: 0;
      }

    .service{
        height: 64%;
        display: block;
        background-size: 80%;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 auto;
        &.brain{
            background-image: url('../../assets/img/profile.png');
            background-size: 62%;
    
        }
        &.hand{
            background-image: url('../../assets/img/profile.png');
            background-size: 62%;
        }
        &.machine{
            background-image: url('../../assets/img/profile.png');
            background-size: 62%;
        }
        &.bulb{
            background-image: url('../../assets/img/profile.png');
            background-size: 50%;
            
        }
    }


    .banner-col{
        
        //background-color: rgba(241, 232, 232, 0.801);
        background-color: rgba(100, 112, 102, 0.116);
        margin: 17px;
        .img-pre {
            height: 130px;
            overflow: hidden;
        }
       
    }
    .imgg{
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 auto;
        clip-path: circle();
    }
    .row p{
        font-size: .8rem;
        font-weight: 500;
        color: #000000b5;
        line-height: 20px;
        text-align: center;
    }
    
}