#AccountVerification{
    text-align: center; 
    padding: 10% 0px;
.icon{
    background-image: url('../../assets//img/success-svgrepo-com.svg');
    height: 60px;
    width:60px;
    margin: 0 auto;
    display: table;
    background-size: cover;
    margin-bottom: 36px;
    padding: 5px;
}
h2{
    color: var(--main-color);
    font-size: 1.5rem;
    
}
}