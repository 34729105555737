.groups {
    button.btn {
        padding: 7px 19px;
        border-radius: 4px;
        text-align: center;
        border: none;
        background-color: #427744;
        color: white;
        height: 30px;
        margin-top: 5px;
        line-height: 1rem;
        font-size: 0.8rem;
        font-weight: 300;
        margin-bottom: 24px;
    }

    .group_list {
        background: #fff;
        padding: 10px;

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style: none;
                display: flex;

                &.headers {
                    background-color: #427744;
                    color: #fff;
                    font-size: 0.8rem;
                    font-weight: 600;
                    padding: 9px 0px 1px 0px;
                    margin-bottom: 20px;
                }

                &.list {
                    font-size: 0.8rem;
                    font-weight: 400;
                    margin-bottom: 18px;
                    border-bottom: 1px solid #e9e4e4;
                    padding-bottom: 10px;

                    .group_img {
                        margin-right: 10px;
                    }
                }

                .group_img {
                    width: 26px;
                    height: 26px;

                    img {
                        width: 100%;
                    }

                    button {
                        background-color: transparent;
                        border: none;
                        position: relative;
                        top: -45px;
                    }
                }

                .group_name {
                    width: 20%;
                    position: relative;
                    padding-left: 23px;

                    .edit {
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }

                .group_admin {
                    width: 20%;
                    position: relative;
                    padding-left: 50px;

                    .add_btn {
                        background-color: #427744;
                        padding: 3px 9px;
                        border-radius: 11px;
                        border: none;
                        color: aliceblue;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }

                .group_members {
                    width: 60%;

                    position: relative;
                    display: block;
                    height: auto;

                    span {
                        background-color: #29532a;
                        padding: 3px 9px;
                        border-radius: 11px;
                        color: #fff;
                        position: relative;
                        min-width: 74px;
                        margin: 3px;
                        height: 25px;
                        display: table;
                        float: left;
                        padding-right: 35px;

                        .delete {
                            padding: 0px;
                            display: block;
                            position: absolute;
                            right: 2px;
                            top: 1px;
                            background-color: #fff;
                            border: none;
                            border-radius: 100%;
                            height: 22px;
                            width: 20px;
                            color: #080808;
                            font-weight: 700;
                        }
                    }

                    .add_btn {
                        background-color: #427744;
                        padding: 3px 9px;
                        border-radius: 11px;
                        border: none;
                        color: aliceblue;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }

            }
        }
    }
}


.groupinfo {
    header {
        h1 {
            text-align: center;
            font-size: 1.5rem;
        }
    }

    background-color: #fff;
    padding: 10px;

    .groupadmin {
        margin-bottom: 10px;

        ul {
            margin: 0px;
            padding: 0px;

            li {
                list-style: none;
                display: flex;
                border-bottom: 1px solid #cccc;
            }
        }
    }

    .member {
        margin-bottom: 10px;

        ul {
            margin: 0px;
            padding: 0px;

            li {
                list-style: none;
                display: flex;
                border-bottom: 1px solid #cccc;
            }
        }
    }

    .trash {
        background-image: url('../../../assets/img/trash.svg');
        height: 30px;
        width: 20px;
        background-size: 72%;
        background-repeat: no-repeat;
        cursor: pointer;
    }
}

option {
    cursor: pointer;
    padding: 10px 0px;

    &:disabled {
        background-color: #d6d2d2;
    }
}

.dropdown-area {
    width: 100%;

    #dropdown-basic {
        width: 100%;
        text-align: left;
        background-color: #fff;
        color: #000;
    }

    .dropdown-menu {
        &.show {
            width: 100%;
            height: 600px;
            overflow-y: hidden;

            a {

                display: flex;

                input {
                    width: 20px;
                    float: initial;
                    line-height: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }
        }
    }
}