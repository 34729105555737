#Terms{

   .banner{
 
    background-color:var(--bg-gray); 
    text-align: center;
    margin-bottom: 30px;
    padding-top: 5%;
    padding-bottom: 5%;
   
       h1{
        font-size:2rem;
        font-weight: 800;
color: var(--main-color);   
       }
   }
   h4{
    font-size:1.5rem;
            font-weight: 800;
    color: var(--main-color);
    line-height: 60px;
   }

}
