#aboutus{

.who-we{

    text-align: center;
    margin-top: 2%;
    h1{
        font-size: 1.6rem;
        font-weight: 300;
        margin: 0 auto;
        color: var(--main-color);
    }
    P{
        text-align: left;
        font-size: 0.8rem;
        line-height: 20px;
    }
}
    


    .discription-box{
        padding: 55px 0px;
        background-color:var(--bg-gray); 
        h2{text-align: center;
            font-size: 1.6rem;
    color: var(--main-color);
    font-weight: 300;
        }
    }

    .story{
        
        padding: 55px 0px;
        h2{text-align: center;
            font-size: 1.6rem;
    color: var(--main-color);
    font-weight: 300;
        }
        p{
            font-family: "Manjari",sans-serif;
            font-size: 0.8rem;
            line-height: 1.5rem;
            font-weight: 400;
        }
    }
#about{
    P{
        text-align: left;
        font-size: 0.8rem;
        line-height: 20px;
       
    }
    

}
}
