#profile{

h1{
        color: var(--main-color);
        font-size: 1rem;
    }
    
    .nav-link {
        font-size: 0.8rem;
        padding: 14px 5px 12px 5px;
        color: rgb(89, 100, 25) !important;
        font-weight: 400;
        letter-spacing: 1px;
    }

        div.bvVjTw{
        display: none;
    }
    h5{
        font-size: 0.9rem;
    }
    h6{
        font-size: 0.9rem;
    }
    label{
        font-size: 0.7rem;
    }
    .btn-invoice{
        background-image: url('../../../assets/img/invoice.svg');
        height: 30px;
        width: 20px;
        background-size: 72%;
        background-repeat: no-repeat;
        cursor: pointer;
        border: none;
        background-color: transparent;
    }
    
}
.user-profile {
    width: 128px;
    border-radius: 100%;
    border: 1px solid #ccc;
    height: 128px;
    overflow: hidden;
    margin: 0 auto;
}
.info{
   padding-top: 29px;
   background-color: #fff;
}
.subscrib{
    
    button{
        background-color: var(--main-color);
        font-size: 0.8rem;
        text-align: center;
        color: #fff;
        border: none;
        margin: 8px;
        text-transform: capitalize;
        width: 100%;
    }
}
.donate{

button{
    background-color: var(--main-color);
    font-size: 0.8rem;
    text-align: center;
  
    color: #fff;
    border: none;    
    margin: 8px;
}
}

.profile-info{
    list-style: none; 
    display: flex;
    flex-wrap: wrap;
    li{
        display: block;
  
    margin-bottom: 16px;
    padding: 10px;width: 16%;
   
    display: block;
        label{
            font-size: 0.8rem;
            display: block;
        }
        span{
            font-size: 0.8rem;
            font-weight: 600;
            display: block;
        }
    }
}

.right-profile{
   // float: right;
   // width: 22%;
    &>div{
       // display: flex;
    }
    img{
        width: 100%;
        float: right;
    }
}

.subscrib_form{

    .modal-dialog {
        max-width: 789px;
        .modal-title{
            color: var(--main-color);
            font-size: 0.9rem;
            text-transform: capitalize;
        }

        ul{
            margin: 0px;
            padding: 0px;
display: flex;
justify-content: space-evenly;
            li{
                list-style: none;
    width: 33%;
    text-align: center;
    border-right: 1px solid #e1dddd;
    padding: 10px;
               .pakgName {
                    font-size: 1.2rem;
                    display: block;
                    margin-bottom: 18px;
                }
            
              .amount {
                    font-size: 1.6rem;
                    font-weight: 600;
                    display: block;
                    position: relative;
                    color: var(--main-color);
                    i{
                        font-size: 1rem !important;
                        margin-right: 3px;
                      
                      
                    }
                    p{
    //                     position: absolute;
    // right: -31px;
    // top: 0;
                    }
                }
                .validity {
                    font-weight: 400;
                    font-size: 0.9rem;
                    display: block;
    margin-bottom: 10px;
                }
            }
        }
    }
    Button{
        background-color: var(--main-color);
        font-size: 0.8rem;
    }
}
.total {
    font-weight: 400;
    font-size: 0.9rem;
    span{
        display: block;
        margin-bottom: 10px;
    }
}

#Success{
    text-align: center; 
    padding: 10% 0px;
.icon{
    background-image: url('../../../assets//img/success-svgrepo-com.svg');
    height: 60px;
    width:60px;
    margin: 0 auto;
    display: table;
    background-size: cover;
    margin-bottom: 36px;
    padding: 5px;
}
h2{
    color: var(--main-color);
    font-size: 1.5rem;
    
}
}

#profile{
    .nav-tabs{
        border-bottom: none;
        li{
            &:after{
                display: none;
            }
            .nav-link{
&.active{
    background-color:var(--main-color);
    color: #fff !important;
    font-size: 0.8rem !important;
    font-weight: 400;
    padding: 12px 29px !important;
}
            }
        }
    }
}

.Subscribed{
    text-align: center;
    margin-bottom: 200px;
    padding-top: 16%;
    position: absolute;
    top: 0;
    bottom: -1px;
    height: 100%;
    width: 100%;
    background-color: #f0f8ffeb;
    z-index: 999;
}

.profile_info{
    position: relative;
}

.user-profile-ingo{
    text-align: center;
    display: block;
    margin-bottom: 10px;
    h5{
        font-size: 0.9rem;
        font-weight: 600;
    }
    h6{
        font-size: 0.7rem;
      
    }
    span{
        background-color: #d00101;
        color: #fff;
        padding: 4px 10px;
        margin-bottom: 10px;
        border-radius: 12px;
        font-size: 0.8rem;
        font-weight: 600;
    }
}