#gallery{

    .card{
        width: 14%;
    }
    .row{
        //background-color: yellow;
        display: flex;
        width: auto;
        margin-top: 20px;
    }
    .gallery{
        //height: 195px;
        width: 240px;
        background-color: white;
        margin: 17px;
        padding: 10px;
    }
    .gallery-trash{
        background-image: url('../../../assets/img/trash.svg');
        height: 30px;
        width: 20px;
        background-size: 72%;
        background-repeat: no-repeat;
        cursor: pointer;
        float: right;
    }

    .img-pre{
        margin-top: 31px;
    }
}