#activity{
    margin-top: 20px;
    background-color: #fff;
    h1{
        font-size: 1.6rem;
        color: var(--main-color);
        font-weight: 300;
        text-transform: capitalize;
    }
.activity-row{
    .activity-row-items-box{
        background-color: #f8f4f4;
        border: 1px solid #f4f1f1;

    //min-height: 485px;
    margin-bottom: 20px;
    //max-height: 485px;
a{
    padding: 0px !important;
    color: #3d3b3b !important
}
        h5{
            font-size: 0.9rem;
            overflow: hidden !important;
            text-overflow: ellipsis;
            height: 54px;
            cursor: pointer;
            background-color: transparent;
            color: #3d3b3b !important;
            padding: 9px;
            line-height: 23px;
            font-weight: 700;
            position: inherit;

    padding: 9px;

    font-weight: 500;
        font-family: 'Manjari', sans-serif
        }
        p{  font-size: 0.8rem;
            padding: 10px;
           
            display: block;
    word-break: break-all;
    overflow: hidden !important;
    text-overflow: ellipsis;
    height: 100px;
    font-weight: 500;
        font-family: 'Manjari', sans-serif
        }
        .created-by{
            font-size: 0.7rem;
        }
        .more{
            float:right;
            font-size: 0.7rem;
            a{
                font-weight: 600; color: black !important;
    padding: 0px;
    font-size: 0.8rem;
    text-align: right;
    padding: 0px !important;
            }
        }
    }
}
.footer-area {
    padding: 0px 3px;
}
}

#activity_details{
    margin-top: 20px;
    h1{
        font-weight: 600; 
        color: black !important;
    padding: 0px;
    font-size: 1.2rem;
    margin-top: 20px;
    }
    p{
        
    line-height: 24px;

    }
    .createdAt{
        display: block;
        font-size: 0.8rem;
        margin-top: 20px;  
    }
    span.social {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        color: var(--main-color);
    }
}