#registration{

    margin: 0 auto;
    margin-top: 3%;

    .form-control{
        height: 35px;
    font-size: 0.8rem;
    }
    .form-label {
        margin-bottom: 0.5rem;
        font-weight: 400;
        font-size: 0.8rem;
    }
    .button-remove{
        height: 17px;
        width: 25px;
        margin-top: 23px;
        background: transparent;
        /* border-color: #4C5034; */
        color: #4C5034;
        text-align: center;
        /* border-radius: 15%; */
        font-weight: 800;
        font-size: 0.8rem;
        display: table;
        border: none;
    }
    .mb-3{
        margin-bottom: 0rem!important;
    }
    .form-select{
        height: 35px;
        font-size: 0.8rem;
    }
    .ex_service{
        display: flex;
        font-size: 0.8rem;
    }
    .family-btn
    {
        background-color: var(--main-color);
        width: 20px;
    height: 20px;
    border-radius: 69%;
    float: right;
    }

    i.fas.fa-plus-circle {
        float: right;
        color: var(--main-color);
        cursor: pointer;
        position: relative;
        top: -18px;
        right: -22px;
        font-size: 22px;
    }
    i.fas.fa-trash-alt{
        float: right;
        position: absolute;
        left: 3px;
        top: 44px;
        font-size: 0.8rem;
        cursor: pointer;
        display: block;
        width: 20px;
    }
.family{
    position: relative;
    padding-left: 34px;
}
    .register{
        background-color: var(--main-color);
    font-size: 0.8rem;
    text-align: center;
    margin: 0 auto;
    display: table;
    border: none;
    width: 30%;
    height: 32px;

    &:focus{
        border: none;
        box-shadow: none;
    }
    }

    .footer-area{
        padding: 10px;
    font-size: 0.8rem;
    display: block;
    margin-bottom: 30px;
    .forgot{
        float: left;
    }
    .registration{
        float: right;
    }
    a{
        float: right;
        margin-bottom: 21px;
        font-size: 0.8rem;
        color: #427744;
        font-weight: 500;
    }
    }
    .mandatory{
        color: rgb(200, 28, 28);
        font-size: .9rem;
        font-weight: 400;
    }
    .form-control{
        margin: 0px;
    }
    .file-upload-field{
        margin: 0px;
    }
    .form-check-label{
        margin-right: 10px;
    }
    .imageUpload {
        background: #d1cdc6;
        text-align: center;
        height: 200px;
        position: relative;
        .icon{
            width: 60px;
            height: 60px;
            background-image: url('../../assets/img/cloud-computing.png');
            position: absolute;
            background-size: cover;
            cursor: pointer;
    top: 50%;
    left: 40%;
    &::before{
        left: -75px;
        content: "Upload Profile Image";
        position: absolute;
        top: -27px;
        width: 210px;
        text-align: center;
        font-weight: 600;
    }
        }
        img{
            height: auto;
            width: 134px;
        }
       
    
    &.doc{
        .icon{  
            &::before {
                
                content: "Upload Doc Image";
        }
    }
    
}
}
}

.rg-btn-area{
    position: relative;
    .register{
        position: absolute;
    bottom: 0;
    right: 0;
    }
}